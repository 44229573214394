import React from "react"

import { withGiftcardItem } from "./withGiftcardItem"
import { Icon } from "../../Icon/Icon"
import { StyledErrors } from "../../Styled/Form"
import { StyledImage } from "../../Styled/Image"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { P } from "../../Styled/Text"
import { Card, Image, Content, Balance, Buttons, Button, ContentRow, Remaining, Toggle, CodeLabel, Code, Link, Column } from "./styledGiftcardItem"

export const GiftcardItem = withGiftcardItem(
  ({ applied, card, errors, handleApply, handlePrint, handleShow, image, loading, locales, show }): JSX.Element => (
    <>
      {card ? (
        <>
          <Card>
            <Image>
              <StyledImage fluid={image} />
              <Balance colour={`white`}>{card?.balance?.remaining}</Balance>
            </Image>
            <Content>
              <ContentRow items={`start`} withWrap>
                <StyledColumn width={`md:1/2`}>
                  <Remaining>{`${locales?.additionalValue} ${card?.balance?.initial}`}</Remaining>
                </StyledColumn>
                <Column width={`md:1/2`}>{card?.expires && <P>Expires: {card?.expires}</P>}</Column>
              </ContentRow>
              <StyledRow items={`end`} withWrap={`<sm`}>
                <StyledColumn width={`grow`}>
                  <CodeLabel>{locales?.additionalCode}</CodeLabel>
                  <Code>{show ? card?.code?.full : card?.code?.partial}</Code>
                </StyledColumn>
                <Column width={`no-shrink`}>
                  <Toggle colour={`white`} onClick={handleShow} title={show ? locales?.additionalHide : locales?.additionalShow}>
                    {show ? locales?.additionalHide : locales?.additionalShow}
                  </Toggle>
                </Column>
              </StyledRow>
            </Content>
          </Card>

          {card?.enabled && (
            <Buttons>
              <Button
                colour={`purple`}
                disabled={applied || loading}
                onClick={handleApply}
                size={`secondary`}
                title={applied ? locales?.additionalApplied : locales?.additionalApply}
              >
                {applied ? locales?.additionalApplied : locales?.additionalApply}
              </Button>
              <Link colour={`grey-darker`} onClick={handlePrint} title={locales?.additionalPrint} underline={`true`}>
                <span />
                <span>{locales?.additionalPrint}</span>
                <Icon name={`right`} />
              </Link>
            </Buttons>
          )}
        </>
      ) : (
        <P align={`center`}>{locales?.additionalEmpty}</P>
      )}

      {errors?.map(item => (
        <StyledErrors key={item?.toString()}>{item?.toString()}</StyledErrors>
      ))}
    </>
  )
)
