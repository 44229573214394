import React, { memo, useCallback, useMemo } from "react"

import { useCore } from "../../../hooks/useCore"
import { useGiftcard } from "../../../hooks/useGiftcard"

export const withGiftcardItem = Component =>
  memo(({ name = "GiftcardItem", image, location, page }: any) => {
    const {
      helpers: { isBrowser },
    } = useCore()

    const { giftcard } = useMemo(() => {
      const parts = location?.pathname?.split(`/`) || []
      const giftcard = parts?.length > 2 ? parts[parts?.length - 1] : null

      return { giftcard }
    }, [location])

    const { applied, card, errors, loading, show, handleApply, handleShow } = useGiftcard(giftcard)

    const handlePrint = useCallback(
      event => {
        !show && handleShow(event)
        setTimeout(() => isBrowser && window.print(), 100)
      },
      [isBrowser, handleShow, show]
    )

    Component.displayName = name
    return useMemo(
      () => (
        <Component
          applied={applied}
          card={card}
          errors={errors}
          handleApply={handleApply}
          handlePrint={handlePrint}
          handleShow={handleShow}
          image={image}
          loading={loading}
          locales={page}
          show={show}
        />
      ),
      [applied, card, errors, handleApply, handlePrint, handleShow, image, loading, page, show]
    )
  })
