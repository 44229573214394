import React from "react"
import { graphql } from "gatsby"

import { Giftcard as Page } from "../components/Giftcard/Giftcard"

export const query = graphql`
  query {
    page: sanityPageGiftcard {
      title
      image: _rawImage(resolveReferences: { maxDepth: 2 })
      metadata: _rawMetadata(resolveReferences: { maxDepth: 4 })
      additionalCode
      additionalValue
      additionalApply
      additionalApplied
      additionalShow
      additionalHide
      additionalPrint
      additionalPrintTitle
      additionalPrintContent
      additionalPrintNote: _rawAdditionalPrintNote(resolveReferences: { maxDepth: 2 })
      additionalPrintTo
      additionalPrintFrom
      additionalEmpty
    }
  }
`

const Component = ({ data, ...props }): JSX.Element => <Page {...props} {...data} />

export default Component
