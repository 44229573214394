import tw, { styled } from "twin.macro"

import { StyledButton, StyledLink } from "../../Styled/Button"
import { StyledColumn } from "../../Styled/Column"
import { StyledRow } from "../../Styled/Row"
import { H3, H6, P } from "../../Styled/Text"

export const Card = tw.figure`flex flex-col w-full bg-purple-dark rounded-lg h-80 overflow-hidden mb-12 md:mb-16`
export const Image = styled.div`
  ${tw`w-full flex-grow relative`}

  > div {
    ${tw`w-full inset-0 absolute!`}
  }
`
export const ContentRow = tw(StyledRow)`mb-4 print:mb-0`
export const Balance = tw(H3)`relative ml-5 mt-5 italic print:text-6xl`
export const Buttons = tw.div`flex flex-col items-center justify-center`
export const Content = tw.figcaption`flex-shrink-0 w-full bg-orange text-white py-4 print:py-3.5 px-5`
export const Column = styled(StyledColumn)`
  ${tw`md:flex md:flex-col md:items-end`}
  ${({ width }) => (width === `no-shrink` ? tw`w-full md:w-auto mt-1 md:mt-0` : tw`mb-2 md:mb-0`)}
`
export const Remaining = tw(P)`print:hidden`
export const CodeLabel = tw(P)`hidden print:block mb-1`
export const Code = tw(H6)`print:text-2xl`
export const Toggle = tw(StyledLink)`print:hidden`
export const Button = tw(StyledButton)`md:w-47.5 print:hidden`
export const Link = tw(StyledLink)`mt-7.5 print:hidden`
