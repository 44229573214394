import tw, { css, styled } from "twin.macro"

import { H3, H5, P, RichText } from "../Styled/Text"

export const Page = tw.section`py-14 md:py-22 print:py-0`
export const Title = tw(H3)`mb-12 md:mb-16 print:hidden`
export const Print = tw.div`hidden mb-12 last:mb-0 text-center print:block`
export const Card = tw.div`print:px-16`
export const Message = tw(P)`w-3/5 mx-auto`
export const Writing = tw.div`mt-8`
export const WritingText = tw(H5)`inline-block w-1/4`
export const WritingBlank = tw.div`inline-block border-b border-orange w-3/4`
export const Note = styled(RichText)`
  ${tw`text-xs mx-auto mt-24`}

  > div > * {
    ${css`
      &::after {
        ${tw`h-1`};
      }
    `}
  }
`
export const Logo = styled.div`
  ${tw`border-b border-orange -mt-12 pb-4 mb-12`}

  svg {
    ${tw`mx-auto`}
  }
`
