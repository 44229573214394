import { useEffect, useState } from "react"

import { useCheckout } from "./useCheckout"
import { useCore } from "./useCore"
import { useShopify } from "./useShopify"

export const useGiftcard = rawGiftcard => {
  const { applyGiftCardCode, checkout } = useCheckout()
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { formatMoney } = useShopify()

  const [card, setCard] = useState(null)
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)

  const getCard = () => {
    try {
      const card = rawGiftcard && JSON.parse(decodeBase64(rawGiftcard))

      if (card?.code)
        setCard({
          ...card,
          balance: {
            initial: formatMoney(card?.balance?.initial),
            remaining: formatMoney(card?.balance?.remaining),
          },
          code: {
            partial: card?.code?.slice(-4)?.padStart(card?.code?.length, "*"),
            full: card?.code
              ?.split(``)
              ?.map((letter: string, index: number) => (index % 4 === 0 ? ` ${letter}` : letter))
              ?.join(``)
              ?.trim(),
          },
          enabled: card?.enabled && !card?.expired,
          expires: card?.expires
            ? new Intl.DateTimeFormat("en-AU", { day: "numeric", month: "long", year: "numeric" }).format(new Date(card?.expires))
            : null,
        })
    } catch (error) {
      setErrors([error?.message])
    }
  }

  const handleApply = async event => {
    event.preventDefault()

    setErrors(null)
    setLoading(true)
    const cards = await applyGiftCardCode(card?.code?.full)
    setLoading(false)

    if (cards?.checkoutUserErrors?.length > 0) {
      setErrors(cards.checkoutUserErrors.map(({ message }) => message))
    }
  }

  const handleShow = event => {
    event.preventDefault()

    setShow(active => !active)
  }

  // intentionally only once at first render
  useEffect(() => {
    getCard()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const applied = checkout?.appliedGiftCards?.find(({ lastCharacters }) => card?.code?.full?.toLowerCase()?.includes(lastCharacters?.toLowerCase()))

  return {
    applied,
    card,
    errors,
    loading,
    show,
    handleApply,
    handleShow,
  }
}
