import React, { memo, useMemo } from "react"

import { useCore } from "../../hooks/useCore"
import { useImage } from "../../hooks/useImage"

export const withGiftcard = Component =>
  memo(({ name = "Giftcard", location, page }: any) => {
    const {
      helpers: { sanityContent },
    } = useCore()
    const { getFluidImage } = useImage()

    const note = sanityContent(page?.additionalPrintNote)
    const image = getFluidImage(page?.image, { maxWidth: 800 })

    Component.displayName = name
    return useMemo(() => <Component image={image} location={location} note={note} page={page} />, [image, location, note, page])
  })
