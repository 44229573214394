import React from "react"

import { withGiftcard } from "./withGiftcard"
import { Icon } from "../Icon/Icon"
import { GiftcardItem } from "./Item/GiftcardItem"
import { StyledContainer } from "../Styled/Container"
import { H5 } from "../Styled/Text"
import { Page, Title, Card, Print, Logo, Message, Writing, WritingText, WritingBlank, Note } from "./styledGiftcard"

export const Giftcard = withGiftcard(
  ({ image, location, note, page }): JSX.Element => (
    <div>
      <Page>
        <StyledContainer width={`sm`}>
          <Title as={`h1`} align={`center`} colour={`orange`} withSpacing={`xl`}>
            {page?.title}
          </Title>
          <Print>
            <Logo>
              <Icon colour={`orange`} name={`logo`} width={300} />
            </Logo>
            <H5 colour={`orange`} withSpacing={`xs`}>
              {page?.additionalPrintTitle}
            </H5>
            <Message>{page?.additionalPrintContent}</Message>
          </Print>
          <Card>
            <GiftcardItem image={image} location={location} page={page} />
          </Card>
          <Print>
            <Writing>
              <WritingText colour={`orange`}>{page?.additionalPrintTo}</WritingText>
              <WritingBlank />
            </Writing>
            <Writing>
              <WritingText colour={`orange`}>{page?.additionalPrintFrom}</WritingText>
              <WritingBlank />
            </Writing>
            <Note>{note}</Note>
          </Print>
        </StyledContainer>
      </Page>
    </div>
  )
)
